.Toolbar {
  max-width: 1200px;

  &.MuiToolbar-root {
    padding: 0;
    height: 6vh;
    width: 100%;
    max-width: 1200px;

  }

  &-Info {
    margin: 20px 20px 0 20px;
    width: 100%;
    justify-content: space-around;
    position: relative;

    &.MuiStack-root {
      flex-direction: row;
    }

  }

  &-Wrapper {
    &.MuiAppBar-root {
      position: relative;
      align-items: center;
    }
  }

  //
  //&-Icon {
  //  &.MuiIconButton-root {
  //    display: none;
  //  }
  //
  //}

  &-Title {
    &.MuiTypography-root {
      padding-left: 20px;
      font-style: italic;
      max-width: 310px;
      font-family: cursive;
      //font-size: 48px;
      font-size: clamp(25px, 5vw, 45px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
    }
  }

  &-Subtitle {
    &.MuiTypography-root {
      font-style: italic;
      max-width: 250px;
      font-size: clamp(15px, 5vw, 25px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
    }
  }

  &-Item {
    border-radius: 10px;
    transition: opacity ease .2s, background-color ease .2s;

    &.MuiTypography-root {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

    }


    &_active {
      opacity: 0.8;
      background-color: #1565c0;
    }

  }



  &-Item:hover {
    cursor: pointer;
    opacity: 0.6;
    background-color: #1565c0;
  }


}


@media (max-width: 768px) {
  .Toolbar {
    &.MuiToolbar-root {
      width: 100%;
    }
  }
}
