.PhotoAlbumList {

  &-Item {
    &.MuiCard-root {
      width: 100%;
      background-color: rgba(255, 231, 201, 0.46);
    }
  }

  &-Image {
    &.MuiCardMedia-root {
      aspect-ratio: 16 / 9;
    }
  }
  &-Description {
    &.MuiTypography-root {
      text-align: justify;
      text-indent: 20px;
    }
  }

  &-Link {
    text-decoration: none;
    transition: opacity .3s;

    &:hover {
      opacity: 0.8;
    }
  }

}

