.Main {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: rgba(255, 255, 255, 0.7);

  &-Title{
    &.MuiTypography-root {

      margin: 10px 50px;
      font-weight: 700;
      text-align: center;
      font-size: clamp(20px, 4vw, 35px);

    }

  }
  &-Subtitle {
    &.MuiTypography-root {
      margin: 10px 50px;
      font-weight: 600;
      font-size: clamp(20px, 4vw, 35px);

    }
  }
  &-Description {
    &.MuiTypography-root {
      margin: 10px 50px;
      font-size: clamp(14px, 4vw, 19px);
      text-indent: 20px;
      text-align: justify;
      word-wrap: break-word;
    }
  }
}


@media (max-width: 1200px) {
  .Main {
    width: 100%;
  }
}


@media (max-width: 768px) {

  .Main {
    &-Title {
      &.MuiTypography-root {

        margin: 0 15px;
      }
    }

    &-Subtitle {
      &.MuiTypography-root {
        margin: 8px 15px;

      }
    }
    &-Description {
      &.MuiTypography-root {
        margin: 0 15px;

      }
    }
  }
}
