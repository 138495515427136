.Contacts {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.7);

  &-Container {
    box-sizing: border-box;
    width: 1200px;
  }

  &-Image {
    width: 100%;
    aspect-ratio: 16/9;


  }


  &-Title {
    width: 100%;

    &.MuiTypography-root {
      font-size: clamp(25px, 4vw, 35px);
      margin: 15px 0;
      font-weight: 700;
      text-align: center;
      font-style: italic;
      flex-grow: 1;
      width: 100%;
    }
  }

  &-Description {
    &.MuiTypography-root {
      font-size: clamp(15px, 4vw, 18px);
      margin: 20px 10px;
      box-sizing: border-box;
      font-weight: 500;
      flex-grow: 1;

    }
  }
  &-Span {
    font-weight: 700;
  }
}
//
//@media (max-width: 768px) {
//  .Contacts {
//    &-Container {
//
//      padding: 0 10px;
//    }
//  }
//}
