.Gallery {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.7);


}

.PhotoAlbumList {
  grid-gap: 40px;
  list-style: none;
  margin: 40px 0;
  padding: 0;


}
