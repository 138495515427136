.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  &::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url("../public/image/main-bg-2.jpg") center/cover no-repeat;
    opacity: 0.5;
  }
}
