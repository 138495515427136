.PhotoAlbum {
  position: relative;
  width: 100%;

  &-Wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
max-width: 1200px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.7);
  }

  &-List {
    grid-gap: 40px;
    list-style: none;
    margin: 40px 0;
    padding: 0;
  }

  &-Item {
    &.MuiCard-root {
      width: 100%;
      background-color: rgba(255, 231, 201, 0.46);
    }
  }
  &-Title {
    margin: 10px;
    text-align: center;
    font-size: clamp(25px, 5vw, 40px);
  }
  &-Divider {
    &.MuiDivider-root {
      margin-bottom: 20px;
      border-color: rgba(0, 0, 0, 0.3);
    }
  }

  &-Image {
    transition: opacity .3s;
    &:hover {
      cursor: pointer;
      opacity: .7;
    }
    &.MuiCardMedia-root {
      aspect-ratio: 16 / 9;

    }
  }

  &-Link {
    text-decoration: none;
    transition: opacity .3s;

    &:hover {
      opacity: 0.8;
    }
  }

}

.ModalPhoto {
  position: relative;


  &-Carousel {
    position: relative;
  }

  &-Image {
    width: auto;
    max-width: 100%;
    height: 80vh;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    object-fit: contain;
    //padding: 40px 0 40px;
    margin: 0 auto;
  }

  &-Title {
    margin: 10px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  &-CloseBtn {
    font-size: 4rem;

    &.MuiButton-root {
      position: absolute;
      top: 20px;
      right: 20px;
      color: #000000;
      font-weight: 700;
      font-size: 2rem;
      z-index: 1000;

    }
  }


}
