.CardList {
  grid-gap: 40px;
  list-style: none;
  margin: 0;
  padding: 0;

  &-Title {
    margin: 10px;
    text-align: center;
    font-size: clamp(25px, 4vw, 35px);
  }

  &-Divider {
    &.MuiDivider-root {
      margin-bottom: 20px;
      border-color: rgba(0, 0, 0, 0.3);
    }
  }
}
