.Price {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: rgba(255, 255, 255, 0.7);
}
