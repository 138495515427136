@import "./scss/common";


body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}


