.Carousel {
  position: relative;
  width: 100%;

  &-Image {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: cover;
    transition: opacity .3s;
  cursor: pointer;
  }


}

.ModalPhoto {
  position: relative;

  &-Carousel {
    position: relative;
  }

  &-Image {
    aspect-ratio: 16 / 9;
    width: 100%;
    max-width: 100%;
    object-fit: contain;
    border-radius: 10px;
    max-height: 100vh;
    margin: 0 auto;
    display: block;
  }

  &-Title {
    margin: 10px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  &-CloseBtn {
    font-size: 4rem;

    &.MuiButton-root {
      position: absolute;
      top: 20px;
      right: 20px;
      color: #000000;
      font-weight: 700;
      font-size: 2rem;
      z-index: 1000;

    }
  }


}

@media (max-width: 475px) {
  .Carousel {
    //margin: 40px 10px 20px 10px;
  }
}
