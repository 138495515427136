.Footer {
  max-width: 1200px;
  color: #FFFFFF;
  margin: 10px;

  &.MuiToolbar-root {
    width: 100%;

  }

  &-Wrapper {

    width: 100%;

    &.MuiBottomNavigation-root {
      width: 100%;
      position: relative;
      background-color: #2580DB;
      height: auto;
    }
  }
}
